import React from "react";
import { Row, Col } from "react-bootstrap";
import AwsLogo from "../../../images/aws-300x180.png";
import AwsPartnerSTS from "../../../images/partners/aws-partner.png";
import AwsPartnerPS from "../../../images/partners/aws-partnerPS.png";

const aws = () => {
  return (
    <div className="partner-detail-card">
      <Row>
      <div className="top-section">
          <div className="logo-wrap">
            <div>
              <img className="img-fluid" src={AwsLogo} alt="PartnerImage" />
            </div>
            <div className="padding-add">
              <h3>Amazon Web Services</h3>
              <div className="lbl-ptnrn">AWS Select Consulting Partner</div>
              <div>
                <a
                  href="https://partners.amazonaws.com/partners/001E00000100a6xIAA/iTelaSoft"
                  target="_blank"
                >
                  Visit Website
                </a>{" "}
                <span></span>
              </div>
            </div>
          </div>
          <div className="partner-badges">
            <img className="img-badge mr-1" src={AwsPartnerSTS} alt="PartnerBadge-STS" />
            <img className="img-badge mr-1" src={AwsPartnerPS} alt="PartnerBadge-PS" />
          </div>
        </div>
      </Row>
      <Row>
        <Col>
          <div className="mt-3">
            <h4>About AWS</h4>
            <p>
              AWS is one of the leading cloud service providers in the world and
              provides a wide range of cloud services for deploying software
              applications. Starting with low level services such as Virtual
              Machines (IaaS), it provides new generation high level managed
              services like Serverless Compute and Data Storage options (PaaS).
              A significant part of iTelaSoft's work is currently leveraging AWS
              as the cloud platform and engaged in services hosting,
              infrastructure management and DevOps.
            </p>
          </div>
          <div className="mt-3">
            <h4>Focus Areas</h4>
            <div className="p"><span className="vdivide">-{" "}</span>Cloud Native Development</div>
            <div className="p"><span className="vdivide">-{" "}</span>AWS Infrastructure Management</div>
            <div className="p"><span className="vdivide">-{" "}</span>AWS DevOps</div>
          </div>
          <div className="mt-3">
            <h4>Operating Countries</h4>
            <p>
              Australia <span className="vdivide">|</span> Singapore{" "}
              <span className="vdivide">|</span> UK{" "}
              <span className="vdivide">|</span> EMEA
            </p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default aws;
